import React, { useRef, useState, useEffect } from 'react';
import ChriateLink from '../../components/Link/Link';
import LinkableIcon from '../../components/LinkableIcon/LinkableIcon';
import { Link, useStaticQuery, graphql } from 'gatsby';

import './MiniFooter.scss';

const MiniFooter = React.forwardRef((props, ref) => {
  const sitemapRef = useRef(null);
  // const [sitemapHeight, setSitemapHeight] = useState(0);
  const year = new Date().getFullYear();
  const [isOpen, setIsOpen] = useState(false);

  let { wp : { acfOptionsThemeOptions: { themeSettings: { instagramUrl, linkedinUrl, vimeoUrl, sitemap } } } } = useStaticQuery(graphql`
    {
      wp {
        acfOptionsThemeOptions {
          themeSettings {
            vimeoUrl
            linkedinUrl
            instagramUrl
            sitemap {
              heading
              links {
                link {
                  url
                  target
                  title
                }
              }
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    // setTimeout(() => {
    // }, 1400);
    window.dispatchEvent(new Event('resize'));

    if(isOpen && window.innerWidth >= 1024) {
      setTimeout(() => {
        window.scroll.scrollTo(window.scroll.maxScroll);
      }, 500);
    }

  },[isOpen])

  // useEffect(() => {
  //   if(sitemapRef.current) {
  //     setSitemapHeight(sitemapRef.current.clientHeight);
  //   }
  // },[sitemapRef])

  return (
    <footer ref={ref} id="contact" className="mini-footer" asscroll="true">
      <div className="container-sm">
        <div className="row">
          <div className="column w-100">
            <div className="mini-footer__socials">
              {instagramUrl ? <LinkableIcon url={instagramUrl} target="_blank" icon={<img src="../../images/instagram.svg"/>} /> : ''}
              {linkedinUrl ? <LinkableIcon url={linkedinUrl} target="_blank" icon={<img src="../../images/linkedin.svg"/>} /> : ''}
              {vimeoUrl ? <LinkableIcon url={vimeoUrl} target="_blank" icon={<img src="../../images/vimeo.svg"/>} /> : ''}
            </div>

            <div className="mini-footer__links">
              <Link to="/privacy-policy">Privacy Policy</Link>
              {/* <Link to="/disclaimer">Disclaimer</Link> */}
              <p>&copy; Blank Canvas {year}</p>
              {sitemap ? <button className={`mini-footer__button${isOpen ? ' active' : ''}`} onClick={() => setIsOpen(!isOpen)}>Sitemap</button> : ''}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="column w-100">
            {sitemap ? <div className={`mini-footer__sitemap${isOpen ? ' open' : ''}`}>
              <div className="mini-footer__sitemap-wrap">
                <div ref={sitemapRef} className="mini-footer__sitemap-links">
                {sitemap.map(({ heading, links }, index) => (
                  <div key={index} className="mini-footer__sitemap-column">
                    {heading ? <p>{heading}</p> : ''}
                    <ul>
                      {links ? links.map(({ link }, j_index) => (
                        <li key={j_index}>
                          <ChriateLink link={link} />
                        </li>
                      )) : ''}
                    </ul>
                  </div>
                ))}
                </div>
              </div>
            </div> : ''}
          </div>
        </div>
      </div>
    </footer>
   );
})

export default MiniFooter;
/**
 *
 * This is the Modules WP page template with the Flexible fields
 *
 */

import React, { useContext } from "react"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import * as ACFModules from "../modules/Modules"
import Fallback from "../modules/Fallback"
import ASScroll from "../components/ASScroll/ASScroll"
import MiniFooter from "../modules/MiniFooter/MiniFooter"
// import { Context } from '../utils/store';

const ModulePages = ({ data: { wpPage, wpMenu, wp, allWpGfForm } }) => {
  const { template, slug, uri, ancestors, seo } = wpPage
  const {
    verticalModules: { modules },
  } = template
  // const [state, dispatch] = useContext(Context);

  // dispatch({ type: 'scrollType', payload: 'vertical' });

  return (
    <>
      <ASScroll horizontalScroll={false} />
      <Seo seo={seo} />
      <main
        className={`page page--modules vertical-page slug-${slug}`}
        asscroll-container="true"
      >
        {modules &&
          modules.map((module, id) => {
            if (Object.keys(module).length > 0) {
              const moduleName = module.fieldGroupName.replace(
                "VerticalTemplate_Verticalmodules_Modules_",
                ""
              )
              const Component = ACFModules[moduleName] || Fallback

              if (
                moduleName === "Form" ||
                moduleName === "SupportBlock" ||
                moduleName === "CallToAction"
              ) {
                return <Component key={id} {...module} forms={allWpGfForm} />
              }
              return <Component key={id} {...module} />
            }
          })}
        <MiniFooter />
      </main>
    </>
  )
}

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      slug
      uri
      seo {
        canonical
        cornerstone
        metaKeywords
        metaDesc
        metaRobotsNoindex
        title
      }
      ancestors {
        nodes {
          ... on WpPage {
            title
            uri
            slug
          }
        }
      }
      template {
        ... on WpVerticalTemplate {
          verticalModules {
            modules {
              ... on WpVerticalTemplate_Verticalmodules_Modules_ImageAndText {
                fieldGroupName
                moduleId
                backgroundColour
                imgBackgroundColour
                layout
                spacing {
                  top
                  bottom
                  topMobile
                  bottomMobile
                }
                image {
                  caption
                  localFile {
                    extension
                    publicURL
                    childImageSharp {
                      gatsbyImageData(quality: 100, placeholder: NONE)
                    }
                  }
                }
                mp4PreviewVideo
                fullVideo
                text
              }
              ... on WpVerticalTemplate_Verticalmodules_Modules_Image {
                fieldGroupName
                moduleId
                backgroundColour
                imageWidth
                spacing {
                  top
                  bottom
                  topMobile
                  bottomMobile
                }
                image {
                  caption
                  altText
                  localFile {
                    extension
                    publicURL
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                    }
                  }
                }
                mp4PreviewVideo
                fullVideo
              }
              ... on WpVerticalTemplate_Verticalmodules_Modules_Text {
                fieldGroupName
                moduleId
                backgroundColour
                spacing {
                  top
                  bottom
                  topMobile
                  bottomMobile
                }
                borderPosition
                subheading
                text
              }
              ... on WpVerticalTemplate_Verticalmodules_Modules_VerticalSpacer {
                fieldGroupName
                backgroundColour
                spacing {
                  top
                  bottom
                  topMobile
                  bottomMobile
                }
                borderLine
              }
              ... on WpVerticalTemplate_Verticalmodules_Modules_PageNavigation {
                backgroundColour
                fieldGroupName
                moduleId
                links {
                  link {
                    target
                    title
                    url
                  }
                }
                spacing {
                  bottom
                  bottomMobile
                  fieldGroupName
                  top
                  topMobile
                }
              }
              ... on WpVerticalTemplate_Verticalmodules_Modules_CallToAction {
                backgroundColour
                fieldGroupName
                moduleId
                text
                showForm
                spacing {
                  topMobile
                  top
                  bottomMobile
                  bottom
                }
              }
              ... on WpVerticalTemplate_Verticalmodules_Modules_ScrollingGallery {
                fieldGroupName
                moduleId
                spacing {
                  bottom
                  bottomMobile
                  top
                  topMobile
                }
                subheading
                showOnDesktopOnly
                showOnMobileOnly
                scrollingGallery {
                  backgroundColour
                  heading
                  text
                  link {
                    target
                    title
                    url
                  }

                  image {
                    caption
                    altText
                    localFile {
                      publicURL
                      extension
                      childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                      }
                    }
                  }
                  video
                }
              }
              ... on WpVerticalTemplate_Verticalmodules_Modules_Accordion {
                backgroundColour
                fieldGroupName
                moduleId
                spacing {
                  bottom
                  bottomMobile
                  fieldGroupName
                  top
                  topMobile
                }
                subheading
                text
                accordion {
                  heading
                  text
                }
              }
              ... on WpVerticalTemplate_Verticalmodules_Modules_ThreeIconBlock {
                backgroundColour
                fieldGroupName
                moduleId
                spacing {
                  bottom
                  bottomMobile
                  fieldGroupName
                  top
                  topMobile
                }
                subheading
                text
                icons {
                  image {
                    caption
                    altText
                    localFile {
                      publicURL
                      extension
                      childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                      }
                    }
                  }
                  text
                  additionalText
                }
              }
              ... on WpVerticalTemplate_Verticalmodules_Modules_VerticalFooter {
                fieldGroupName
                moduleId
                formId
              }
              ... on WpVerticalTemplate_Verticalmodules_Modules_Footer {
                fieldGroupName
                moduleId
                formId
              }
              ... on WpVerticalTemplate_Verticalmodules_Modules_JournalFeed {
                backgroundColour
                fieldGroupName
                moduleId
                title
                spacing {
                  bottom
                  bottomMobile
                  top
                  topMobile
                }
              }
              ... on WpVerticalTemplate_Verticalmodules_Modules_ProjectGrid {
                fieldGroupName
                moduleId
                title
                spacing {
                  bottom
                  bottomMobile
                  top
                  topMobile
                }
              }
              ... on WpVerticalTemplate_Verticalmodules_Modules_ThreeStepBlock {
                backgroundColour
                fieldGroupName
                moduleId
                spacing {
                  bottom
                  bottomMobile
                  fieldGroupName
                  top
                  topMobile
                }
                steps {
                  text
                  image {
                    caption
                    altText
                    localFile {
                      publicURL
                      extension
                      childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                      }
                    }
                  }
                }
                text
              }
              ... on WpVerticalTemplate_Verticalmodules_Modules_ThreeColumnBlock {
                backgroundColour
                fieldGroupName
                moduleId
                spacing {
                  bottom
                  bottomMobile
                  fieldGroupName
                  top
                  topMobile
                }
                subheading
                text
                columns {
                  heading
                  text
                  image {
                    caption
                    altText
                    localFile {
                      publicURL
                      extension
                      childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                      }
                    }
                  }
                }
              }
              ... on WpVerticalTemplate_Verticalmodules_Modules_TextAndForm {
                backgroundColour
                fieldGroupName
                moduleId
                text
                formId
                spacing {
                  bottom
                  bottomMobile
                  fieldGroupName
                  top
                  topMobile
                }
              }
              ... on WpVerticalTemplate_Verticalmodules_Modules_CaseStudies {
                backgroundColour
                fieldGroupName
                caseStudies {
                  ... on WpProject {
                    id
                    title
                    uri
                    featuredImage {
                      node {
                        caption
                        altText
                        localFile {
                          publicURL
                          extension
                          childImageSharp {
                            gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                          }
                        }
                      }
                    }
                  }
                }
                moduleId
                text
                spacing {
                  bottom
                  bottomMobile
                  fieldGroupName
                  topMobile
                  top
                }
              }
            }
          }
        }
      }
    }
  }
`

export default ModulePages
